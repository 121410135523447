
import ViewModel from '@/models/ViewModel'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class FooterNav extends ViewModel {
  @Prop({ default: 5 })
  public zIndex: number = 5

  @Prop({ default: '' })
  public footerClass!: string

  @Prop({ default: false })
  public hideCancel!: boolean
}
