import moment from 'moment'
import Api from './Api'
import DatePickerDate from './DatePickerDate'
import WebMessage from './WebMessage'
import Model from './interface/Model'
import router from '../Routes'

const api = new Api()
export default class CommissionRule extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'commission_rules' as string | null,
      plural: 'commission_rules' as string | null,
    },
  }

  public id: null | string = null

  public user_id: null | string = ''

  public product: null | string = 'any'

  public target: string = 'any'

  public commissionable_id: null | string = null

  public commissionable_type: null | string = null

  public payout_source: null | string = 'net'

  public commission: number = 0

  public threshold_escalators: any = []

  public escalator_reset: string = 'quarterly'

  public threshold: any = {
    type: 'quarterly', // none, month, quarter, year
    values: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    },
  }

  public cap: any = 0

  public mode: null | string = 'default'

  public start_at: null | string = moment().startOf('year').format('YYYY-MM-DD')

  public end_at: null | string = moment().endOf('year').format('YYYY-MM-DD')

  public archived_at: null | string = null

  public user: any = null

  public commissionable: any = null

  public threshold_bucket: any = 'none'

  public hide_footer: boolean = false

  public overwrite: boolean = false

  public get formPeriod() {
    if (this.start_at !== null && this.end_at !== null) {
      return new DatePickerDate(this.start_at, this.end_at, null, 'YYYY-MM-DD')
    }
    return null
  }

  public set formPeriod(value: any) {
    if (value.start !== 'Invalid date' && value.end !== 'Invalid date') {
      this.start_at = value.start
      this.end_at = value.end
    } else {
      this.start_at = null
      this.end_at = null
    }
  }

  public get period() {
    const start = moment(this.start_at).format('MMM DD, YYYY')
    const end = moment(this.end_at).format('MMM DD, YYYY')

    return `${start} - ${end}`
  }

  private hasCommissionable() {
    return this.commissionable_id && this.commissionable_type
  }

  private setCommissionable(commissionable: any) {
    this.commissionable_id = commissionable.id
    this.commissionable_type = commissionable.type
  }

  public get formMediaPlanSelect() {
    if (!this.hasCommissionable()) return false
    return this.commissionable_id
  }

  public set formMediaPlanSelect(value: any) {
    this.setCommissionable({ id: value, type: 'MediaPlan' })
  }

  public get formCompanySelect() {
    if (!this.hasCommissionable()) return false
    return this.commissionable_id
  }

  public set formCompanySelect(value: any) {
    this.setCommissionable({ id: value, type: 'Company' })
  }

  public get apiData(): any {
    return {
      id: this.id,
      user_id: this.user_id,
      product: this.product,
      target: this.target,
      commissionable_id: this.commissionable_id,
      commissionable_type: this.commissionable_type,
      payout_source: this.payout_source,
      commission: this.commission,
      threshold_escalators: this.threshold_escalators,
      threshold: this.threshold,
      threshold_bucket: this.threshold_bucket,
      cap: this.cap,
      mode: this.mode,
      start_at: this.start_at,
      end_at: this.end_at,
      overwrite: this.overwrite,
      escalator_reset: this.escalator_reset,
    }
  }

  protected onSave(response: any) {
    if (response.data.message === 'Warning') {
      this.hide_footer = true
      WebMessage.confirm(
        'Do you want to overwrite? This will archive the current rule and create a new valid one.',
        response.data.result.commission_rule,
        { okTitle: 'Create' },
      ).then((value: boolean) => {
        if (value) {
          this.overwrite = true
          this.save().then(() => {
            if (localStorage.getItem('commission_page') !== null) {
              let obj = JSON.parse(localStorage.getItem('commission_page') || '{}')

              router.push({ name: 'commission', query: { invoice_id: obj.invoice_id } })
              return
            }
            router.push({ name: 'commission-rules' })
          })
        }
        this.overwrite = false
        this.hide_footer = false
      })
      return response
    }
    WebMessage.success('Commission rule saved')
    return response
  }

  public toObject(source: any) {
    let instance = this.clone()
    Object.assign(instance, source)

    if (source.start_at) {
      instance.start_at = source.start_at.split(' ')[0]
    }

    if (source.end_at) {
      instance.end_at = source.end_at.split(' ')[0]
    }

    return instance
  }

  public toObjectList(payload: any) {
    return payload.map((item: any) => CommissionRule.toObject(item))
  }

  public get target_details() {
    return {
      company: {
        name: 'Company',
        link: '#',
      },
      mediaplan: {
        name: 'Media Plan',
        link: `sales/media_plan/${this.commissionable_id}/view`,
      },
      any: {
        name: 'User',
        value: 'user',
        link: '#',
      },
    }
  }

  public static checkUserComissionRules(id: string) {
    return api
      .get(`commission_rules/user/${id}`)
      .then((response: any) => response.data.result.commission_rules)
  }
}
