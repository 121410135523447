import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const percentageMask = createNumberMask({
  prefix: '',
  suffix: '%',
})
export const customNumber = (args: any) => {
  let inner_args = {
    prefix: '',
    suffix: '',
    ...args,
  }
  return createNumberMask(inner_args)
}

export const percentagDecimaleMask = createNumberMask({
  prefix: '',
  decimalLimit: 10,
  allowDecimal: true,
  suffix: '%',
})

export const percentagDecimalNegativeMask = createNumberMask({
  prefix: '',
  decimalLimit: 10,
  allowDecimal: true,
  allowNegative: true,
  suffix: '%',
})

export const currencyMask = createNumberMask({
  prefix: '$',
  decimalLimit: 10,
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
})

export const integerMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
})

export const floatMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
})
