import Model from './interface/Model'

export default class Account extends Model {
  protected api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'account' as string | null,
      plural: 'accounts' as string | null,
    },
  }
}
