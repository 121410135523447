
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import { integerMask, currencyMask, percentagDecimaleMask } from '@/models/interface/Masks'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import AccountPicker from '@/components/AccountPicker/AccountPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import CommissionRule from '@/models/CommissionRule'
import ViewModel from '@/models/ViewModel'
import moment from 'moment'
import IconAction from '@/components/IconAction/IconAction.vue'
import WebMessage from '@/models/WebMessage'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'

@Component({
  components: {
    Widget,
    FormWizard,
    TabContent,
    FormInput,
    InsertionOrderPicker,
    CompanyPicker,
    UserPicker,
    AccountPicker,
    DatePicker,
    SelectPicker,
    CheckboxInput,
    FooterNav,
    IconAction,
  },
})
export default class CommissionRuleEdit extends ViewModel {
  @Ref() readonly formWizard!: any

  @Ref() validator!: any

  @Ref()
  readonly user_picker!: any

  public busy = true

  public step: number = 1

  public commission_rule: CommissionRule = new CommissionRule()

  public load_monthly: boolean = false

  public can_add_thresholds: boolean = true

  public threshold_type_list: any = [
    {
      name: 'Monthly',
      value: 'monthly',
    },
    {
      name: 'Quarterly',
      value: 'quarterly',
    },
    {
      name: 'Yearly',
      value: 'yearly',
    },
  ]

  public mode_list: any = [
    {
      name: 'Ignore Media Plan Link',
      value: 'ignore_media_plan_link',
    },
    {
      name: 'Override Media Plan Link',
      value: 'override_media_plan_link',
    },
    {
      name: 'Default',
      value: 'default',
    },
    {
      name: 'Subordinate Based',
      value: 'subordinate_based',
    },
  ]

  public products_list: any = [
    {
      name: 'Any',
      value: 'any',
    },
    {
      name: 'SSL',
      value: 'ssl',
    },
    {
      name: 'CCL',
      value: 'ccl',
    },
  ]

  public payout_source_list: any = [
    {
      name: 'Gross',
      value: 'gross',
    },
    {
      name: 'Net',
      value: 'net',
    },
  ]

  public selected_months: any = {}

  public target_list: any = [
    {
      name: 'Any',
      value: 'any',
    },
    {
      name: 'Billable Client',
      value: 'client',
    },
    {
      name: 'Agency',
      value: 'agency',
    },
    {
      name: 'Station',
      value: 'station',
    },
    {
      name: 'Advertiser',
      value: 'advertiser',
    },
    {
      name: 'Media Plan',
      value: 'media_plan',
    },
    {
      name: 'New Business',
      value: 'new_business',
    },
  ]

  public get companyType() {
    if (this.commission_rule.target === 'agency' || this.commission_rule.target === 'advertiser' || this.commission_rule.target === 'station') {
      return this.commission_rule.target
    }
    return null
  }

  public wait_and_ignore: boolean = false

  public low_value_inputs_index: any = []

  // @Watch this.commission_rules.threshold_escalators
  @Watch('commission_rule.threshold_escalators')
  public onThresholdEscalatorsChange(val: any) {
    if (val && val.length === 1 && val[0].threshold_escalator > 0) {
      this.can_add_thresholds = true
    }
    if (this.low_value_inputs_index.length > 0) {
      // make the array unique
      this.low_value_inputs_index = [...new Set(this.low_value_inputs_index)]
    }
  }

  // watch low_value_inputs_index
  @Watch('low_value_inputs_index')
  public onLowValueInputsIndexChange(val: any) {
    if (val && val.length > 0) {
      this.can_add_thresholds = false
    }
  }

  public get cantChangeUser() {
    // return true to disable input
    if (this.$route.params.id && !this.$route.query.hasOwnProperty('clone')) {
      return true
    }

    if (!this.$route.params.id && !this.$route.query) {
      return true
    }

    return false
  }

  public get threshold_bucket_list() {
    let options = [
      {
        name: 'None',
        value: 'none',
      },
      {
        name: 'User Level',
        value: 'user_level',
      },
      {
        name: 'Business Entity Level',
        value: 'business_level',
      },
    ]

    if (this.commission_rule.product && this.commission_rule.product !== 'any') {
      options.push({
        name: 'Product Entity Level',
        value: 'product_level',
      })
    }

    return options
  }

  public get target_details() {
    return {
      company: {
        name: 'Company',
        link: '#',
      },
      media_plan: {
        name: 'Media Plan',
        link: `sales/media_plan/${this.commission_rule.commissionable_id}/view`,
      },
      advertiser: {
        name: 'Advertiser',
        link: '#',
      },
      station: {
        name: 'Station',
        link: '#',
      },
      agency: {
        name: 'Agency',
        link: '#',
      },
      any: {
        name: 'User',
        value: 'user',
        link: '#',
      },
      new_business: {
        name: 'New Business',
        value: 'new_business',
        link: '#',
      },
    }
  }

  @Watch('commission_rule.threshold.type')
  public onThresholdTypeChange(val: any) {
    this.checkDynamicInputs(val)
  }

  public get masks() {
    return {
      integerMask,
      currencyMask,
      percentagDecimaleMask,
    }
  }

  public checkDynamicInputs(val) {
    this.load_monthly = false
    if (!this.$route.params.id) {
      Vue.set(this.commission_rule.threshold, 'values', {})
    }

    if (this.wait_and_ignore) return

    this.selected_months = {}

    let actions: any = {
      monthly: () => {
        let obj: any = {};
        [...Array(12).keys()].forEach(month => {
          obj[month + 1] = 0
        })
        Vue.set(this.commission_rule.threshold, 'values', obj)
        setTimeout(() => {
          this.load_monthly = true
        }, 300)
      },
      quarterly: () => {
        let obj: any = {};
        [...Array(4).keys()].forEach(month => {
          obj[month + 1] = 0
        })
        Vue.set(this.commission_rule.threshold, 'values', obj)
      },
      yearly: () => {
        Vue.set(this.commission_rule.threshold, 'values', 0)
      },
      none: () => {
        actions.yearly()
      },
    }

    if (actions[val]) {
      actions[val]()
    }
  }

  public mounted() {
    if (this.$route.params.id) {
      this.wait_and_ignore = true
      this.commission_rule.find(this.$route.params.id).then((response: any) => {
        this.commission_rule = response

        this.busy = false
        this.loading = false

        setTimeout(() => {
          if (this.commission_rule.threshold.type) {
            this.checkDynamicInputs(this.commission_rule.threshold.type)
          }
          this.formWizard.activateAll()
          this.wait_and_ignore = false
        }, 1000)
      })
    } else {
      if (this.$route.query.user_id) {
        // @ts-ignore
        this.commission_rule.user_id = this.$route.query.user_id
      }
      this.loading = false
      this.busy = false
    }

    setTimeout(() => {
      if (this.commission_rule.user_id && this.user_picker.local_value.length) {
        this.commission_rule.user = {
          id: this.user_picker.local_value,
          name: this.user_picker.label_name,
        }
      }
    }, 1000)
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public formatLabel(month: number, format = 'MMM') {
    return moment()
      .month(month - 1)
      .format(format)
  }

  public checkType(type: any) {
    return this.commission_rule.threshold.type === type
  }

  public clear_month_value(month: any) {
    this.commission_rule.threshold.values[month] = 0
  }

  public clear_quarter_value(quarter: any) {
    this.commission_rule.threshold.values[quarter] = 0
  }

  public selecteMediaPlan(e: any) {
    this.commission_rule.commissionable = e
  }

  public selecteCompany(e: any) {
    this.commission_rule.commissionable = e
  }

  public selectUser(e: any) {
    this.commission_rule.user = e
  }

  public onSubmit() {
    this.loading = true
    this.commission_rule
      .save()
      .then(response => {
        if (response.status === 200) {
          if (response.data.message !== 'Warning') {
            if (localStorage.getItem('commission_page') !== null) {
              let obj = JSON.parse(localStorage.getItem('commission_page') || '{}')

              this.$router.push({ name: 'commission', query: { invoice_id: obj.invoice_id } })
              return
            }
            this.$router.push({ name: 'commission-rules' })
          }
        }
        this.loading = false
      })
      .catch(error => {
        this.loading = false
      })
  }

  public removeThresholdEscalator(index: any) {
    this.commission_rule.threshold_escalators.splice(index, 1)
    let _index = this.low_value_inputs_index.indexOf(index)
    this.low_value_inputs_index.splice(_index, 1)
  }

  public addThresholdEscalator() {
    this.commission_rule.threshold_escalators.push({
      escalator: 0,
      threshold_escalator: 0,
    })
  }

  // Generates the rules for the escalators
  public setRule(index: any) {
    let length = this.commission_rule.threshold_escalators.length
    let prev_exists = index - 1 >= 0
    let next_exists = index + 1 < length
    let rule = 'required|numeric|between:'
    let _max = ''
    let _min = '0'

    // if last index

    if (index === 0) {
      // check if there is next index, no prev
      // if (next_exists) _max = `,@threshold-escalator-${index + 1}`
      if (next_exists) _max = `,${this.commission_rule.threshold_escalators[index + 1].threshold_escalator}`
      else _max = ''
      return 'required|numeric|min:0'
    }
    if (index === length - 1) {
      return `required|numeric|min:${
        this.commission_rule.threshold_escalators[index - 1].threshold_escalator
      } `
    }
    // threshold-escalator-
    // if (next_exists) _max = `,@threshold-escalator-${index + 1}`
    if (next_exists) _max = `,${this.commission_rule.threshold_escalators[index + 1].threshold_escalator}`
    else _max = ''

    // check previous index
    if (prev_exists) _min = `${this.commission_rule.threshold_escalators[index - 1].threshold_escalator}`
    else _min = '0'

    return `${rule}${_min}${_max}`
  }

  public syncInvalidValue() {
    let length = this.commission_rule.threshold_escalators.length

    const removeFromErrors = (index: any) => {
      this.low_value_inputs_index.splice(index, 1)
    }
    const next_block = (current_index: any) => {
      const next_index = current_index + 1
      if (next_index >= length) return
      const current_value = this.commission_rule.threshold_escalators[current_index].threshold_escalator
      const next_item = this.commission_rule.threshold_escalators[next_index].threshold_escalator

      // check next block
      if (next_item <= current_value) {
        this.low_value_inputs_index.push(current_index)
        this.low_value_inputs_index.push(current_index + 1)
      }
      // check current block
      if (next_item > current_value) {
        const current_idx = this.low_value_inputs_index.indexOf(current_index)
        const next_idx = this.low_value_inputs_index.indexOf(current_index + 1)
        removeFromErrors(next_idx)
        removeFromErrors(current_idx)
      }

      if (current_value === 0) {
        // the current item is higher or equal than the next block
        this.low_value_inputs_index.push(current_index)
        this.low_value_inputs_index.push(current_index + 1)
      }
    }
    const prev_block = (current_index: any) => {
      const prev_index = current_index - 1
      const current_value = this.commission_rule.threshold_escalators[current_index].threshold_escalator
      const prev_item = this.commission_rule.threshold_escalators[prev_index].threshold_escalator
      // the previous items is higher or equal than the next block
      if (prev_item >= current_value) {
        this.low_value_inputs_index.push(current_index)
        this.low_value_inputs_index.push(prev_index)
      }

      if (current_value > prev_item) {
        const prev_idx = this.low_value_inputs_index.indexOf(prev_index)
        const current_idx = this.low_value_inputs_index.indexOf(current_index)

        if (prev_idx >= 0) {
          removeFromErrors(prev_idx)
        }
        if (current_idx >= 0) {
          removeFromErrors(current_idx)
        }
      }

      if (current_value === 0) {
        this.low_value_inputs_index.push(current_index)
      }
    }
    this.low_value_inputs_index = [...new Set(this.low_value_inputs_index)]

    this.commission_rule.threshold_escalators.forEach((item: any, index: number) => {
      const has_next = index + 1 < length
      const has_prev = index - 1 >= 0

      if (index === 0 && has_next) {
        next_block(index)
      }
      if (item.threshold_escalator === 0) {
        Vue.set(this, 'low_value_inputs_index', [...this.low_value_inputs_index, index])
      }
      if (index > 0) {
        prev_block(index)
        if (has_next) {
          next_block(index)
        }
      }
    })

    if (this.low_value_inputs_index.length === 0) {
      this.can_add_thresholds = true
      return true
    }
    this.low_value_inputs_index.forEach((value: any) => {
      if (value > 0) {
        prev_block(value)
      }
      if (value < length) {
        next_block(value)
      }
    })

    this.low_value_inputs_index = [...new Set(this.low_value_inputs_index)]
    return false
  }

  public checkThresholds(index: number = 0) {
    if (index === 0) return false
    let thresholds = this.commission_rule.threshold_escalators
    this.can_add_thresholds = true

    let last_index = thresholds.length - 1
    let before_last_index = last_index - 1

    let last_threshold = thresholds[last_index].threshold_escalator
    let before_last_threshold = thresholds[before_last_index].threshold_escalator
    if (last_threshold > before_last_threshold) {
      let i = this.low_value_inputs_index.indexOf(last_index)
      if (i > -1) {
        this.low_value_inputs_index.splice(i, 1)
      }
      this.can_add_thresholds = true
      return true
    }
    this.low_value_inputs_index.push(last_index)
    this.can_add_thresholds = false
    return false
  }

  public notifyProblems() {
    let length = this.low_value_inputs_index.length
    let msg = `You have ${length} invalid values`
    if (length) {
      if (length === 1) {
        msg = 'You have an invalid value'
      }
      WebMessage.warning(msg)
    }
  }

  public cancel() {
    let obj: any = localStorage.getItem('commission_page')
    if (obj) {
      obj = JSON.parse(obj)
      localStorage.removeItem('commission_page')
      this.$router.push({ name: obj.name, query: { invoice_id: obj.invoice_id } })
    } else {
      this.$router.push({ name: 'commission-rules' })
    }
  }
}
